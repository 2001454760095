<template>
  <div id="Stoping-Machine">
    <div class="stoping-machine-body">
      <div class="user-revenue-statistics">
        <div class="top">
          <div class="left currency-info">
            <div class="currency-logo">
              <div class="image-transform logo">
                <img
                  :alt="currencys.select"
                  :src="`${$public}/images/logo/currency/${currencys.select}.png`"
                  :srcset="`
                ${$public}/images/logo/currency/${currencys.select}-30w.png 576w,
                ${$public}/images/logo/currency/${currencys.select}-60w.png 768w,
                ${$public}/images/logo/currency/${currencys.select}-120w.png 1920w,
                    `"
                  :style="{ width: '100%', height: '100%' }"
                />
              </div>
            </div>
            <div class="currency-total-revenue">
              <p>算力服务总收益</p>
              <!-- <p>{{ income_statistics.total_assets }}</p> -->
            </div>
          </div>
          <div class="right">
            <a-select
              ref="currencys-select"
              :value="currencys.select"
              :style="{ width: '100%' }"
              @change="currencys_select_change"
            >
              <a-select-option
                v-for="(item, i) in currencys.item"
                :key="i"
                :value="item.currency"
              >
                {{ item.alias }}
              </a-select-option>
            </a-select>
          </div>
        </div>
        <div class="bottom">
          <a-spin :spinning="income_statistics.loading" tip="Loading...">
            <a-row :gutter="[15, 15]">
              <a-col :span="12">
                <div>
                  <p>已购算力</p>
                  <p>
                    <span>{{ income_statistics.total_capacity }}</span>
                  </p>
                </div>
              </a-col>
              <a-col :span="12">
                <div>
                  <p>
                    流通释放&nbsp;
                    <a-tooltip arrow-point-at-center placement="topRight">
                      <template slot="title">
                        <span>收益总释放数量</span>
                      </template>
                      <a-icon type="info-circle" />
                    </a-tooltip>
                  </p>
                  <p>
                    <span>{{
                      income_statistics.total_circulation_release
                    }}</span>
                  </p>
                </div>
              </a-col>
              <a-col :span="12">
                <div>
                  <p>
                    收益锁仓&nbsp;
                    <a-tooltip arrow-point-at-center placement="topLeft">
                      <template slot="title">
                        <span> 根据官方释放规则进行线性释放 </span>
                      </template>
                      <a-icon type="info-circle" />
                    </a-tooltip>
                  </p>
                  <p>
                    <span>{{ income_statistics.total_lock_ledge }}</span>
                  </p>
                </div>
              </a-col>
              <a-col :span="12">
                <div>
                  <p>
                    累计提币数量&nbsp;
                    <a-tooltip arrow-point-at-center placement="topRight">
                      <template slot="title">
                        <span>历史提现数量总和</span>
                      </template>
                      <a-icon type="info-circle" />
                    </a-tooltip>
                  </p>
                  <p>
                    <span>{{ income_statistics.total_withdraw }}</span>
                  </p>
                </div>
              </a-col>
              <a-col
                :span="12"
                v-if="
                  income_statistics.total_gas_burn &&
                  parseInt(income_statistics.total_gas_burn) !== 0
                "
              >
                <div>
                  <p>Gas</p>
                  <p>
                    <span>{{ income_statistics.total_gas_burn }}</span>
                  </p>
                </div>
              </a-col>
            </a-row>
          </a-spin>
        </div>
      </div>
      <div class="user-revenue-details bottom">
        <div class="date-select">
          <div class="left">请选择查询年月：</div>
          <div class="right">
            <a-month-picker
              placeholder="请选择查询年月"
              :style="{ width: '100%' }"
              :default-value="income_journal.month"
              @change="getIncomeJournalList"
            />
          </div>
        </div>
        <div class="bottom">
          <div class="income-journal">
            <a-spin :spinning="income_journal.loading" tip="Loading...">
              <a-collapse
                v-if="income_journal.datas.length"
                accordion
                v-model="income_journal.rowShowIndex"
                id="income_journal"
                ref="income_journal"
                :bordered="false"
                :defaultValue="null"
              >
                <a-collapse-panel
                  v-for="(item, key) in income_journal.datas"
                  :key="`${key}`"
                  :class="`a-collapse-panel-${key}`"
                  :showArrow="false"
                >
                  <template slot="header">
                    <p>
                      <span>
                        今日14:00收益
                        <a-tooltip arrow-point-at-center placement="topRight">
                          <template slot="title">
                            <span>当日收益总和</span>
                          </template>
                          <a-icon type="info-circle" />
                        </a-tooltip>
                        ：&nbsp;
                      </span>
                      <span>{{ item.daily_output }}</span>
                    </p>
                    <p>
                      <span>{{ item.day }}</span>
                      <span @click="collapseOnClick(key)">
                        <span v-if="income_journal.rowShowIndex === key + ''">
                          <span :style="{ color: '#999' }">收起明细</span>
                          <a-icon type="up" />
                        </span>
                        <span v-else>
                          <span :style="{ color: '#999' }">展开明细</span>
                          <a-icon type="down" />
                        </span>
                      </span>
                    </p>
                  </template>
                  <a-row>
                    <a-col :span="24">
                      <span>
                        流通释放
                        <a-tooltip arrow-point-at-center placement="topRight">
                          <template slot="title">
                            <span>可用收益（已扣除托管费）</span>
                          </template>
                          <a-icon type="info-circle" />
                        </a-tooltip>
                      </span>
                      <span :style="{ color: 'red' }">
                        {{ item.daily_circulation_release }}
                      </span>
                    </a-col>
                    <a-col :span="24">
                      <span>收益锁仓</span>
                      <span>{{ item.daily_lock_ledge }}</span>
                    </a-col>
                    <a-col :span="24" v-if="false">
                      <span>扇区质押</span>
                      <span>{{ item.daily_sector_ledge }}</span>
                    </a-col>
                    <a-col
                      :span="24"
                      v-if="
                        item.daily_gas_burn &&
                        parseInt(item.daily_gas_burn) !== 0
                      "
                    >
                      <span>Gas</span>
                      <span>{{ item.daily_gas_burn }}</span>
                    </a-col>
                    <template v-if="item.asset_details">
                      <!-- <a-divider orientation="left">其他</a-divider> -->
                      <a-col
                        :span="24"
                        v-for="(value, index) in item.asset_details"
                        :key="index"
                      >
                        <span>
                          <span>{{ value.currency }}&nbsp;&nbsp;</span>
                          <span>{{ value.title }}</span>
                        </span>
                        <span
                          :style="{
                            color:
                              value.content[0] === '-'
                                ? 'green'
                                : value.content[0] === '+'
                                ? 'red'
                                : 'white',
                          }"
                        >
                          {{ value.content }}
                        </span>
                      </a-col>
                    </template>
                  </a-row>
                </a-collapse-panel>
              </a-collapse>
              <a-empty v-else />
            </a-spin>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

import infiniteScroll from "vue-infinite-scroll";
import { Select, Tooltip, Divider, Collapse, DatePicker } from "ant-design-vue";
Vue.use(Select).use(Tooltip).use(Divider).use(Collapse).use(DatePicker);
Vue.component(DatePicker.MonthPicker);

// import moment from "moment";

export default {
  directives: {
    infiniteScroll,
  },
  data() {
    return {
      currencys: {
        select: "",
        item: [],
      },
      income_statistics: {
        loading: false,
        source_type: 1,
        total_capacity: "0.0000",
        total_circulation_release: "0.0000",
        total_gas_burn: "0.0000",
        total_lock_ledge: "0.0000",
        total_sector_ledge: "0.0000",
      },
      income_journal: {
        datas: [],
        loading: false,
        busy: false,
        pagination: {
          page_index: 1,
        },
        month: `${new Date().getFullYear()}-${
          new Date().getMonth() + 1 < 10
            ? "0" + (new Date().getMonth() + 1)
            : "" + (new Date().getMonth() + 1)
        }`,
        rollingDirection: "down",
        rowShowIndex: "-1",
      },
    };
  },
  // 方法
  methods: {
    // moment,
    async getCurrencys() {
      await this.$axios.get("sys-currencys").then((res) => {
        if (res.code === 0) {
          this.currencys.item = [];
          for (let i in res.data)
            if (res.data[i].currency !== "XCH")
              this.currencys.item.push(res.data[i]);
          this.currencys.select = this.currencys.item[0].currency;
          this.getInfo();
          this.getIncomeJournalList();
        }
      });
    },
    async getInfo() {
      const params = {
        currency: this.currencys.select,
      };
      this.income_statistics.loading = true;
      await this.$axios
        .get("personal-center/income-statistics", {
          params,
        })
        .then((res) => {
          this.income_statistics.loading = false;
          if (res.code === 0) {
            Object.assign(this.income_statistics, res.data);
            if (!this.income_statistics.daily)
              this.income_statistics.daily = [];
          }
        });
    },
    async getIncomeJournalList(a, b) {
      this.income_journal.loading = true;
      const params = {
        month: b ? b : this.income_journal.month,
        currency: this.currencys.select,
      };
      await this.$axios
        .get("personal-center/income-journal", {
          params,
        })
        .then((res) => {
          this.income_journal.loading = false;
          if (res.code === 0)
            this.income_journal.datas = res.data ? res.data : [];
        });
    },
    currencys_select_change(currency) {
      this.currencys.select = currency;
      this.getInfo();
      this.getIncomeJournalList();
    },
  },
  created() {
    this.getCurrencys();
  },
};
</script>
<style lang="less">
#Stoping-Machine {
  height: 100%;

  > .stoping-machine-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    > .user-revenue-statistics {
      background-color: #3d447b;
      border-radius: 0.8em;
      padding: 1em;
      flex: 0 0 auto;

      > .top {
        display: flex;
        align-items: center;

        > div {
          flex: 0 0 auto;

          &.left {
            display: flex;

            > .currency-total-revenue {
              margin-left: 0.5em;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
            }
          }

          &.right {
            flex: 0 1 100%;
            margin-left: 1em;
          }
        }
      }

      > .bottom {
        .ant-row > .ant-col > div > p {
          line-height: 1.8em;

          &:first-child {
            color: #bcbcbc;
            font-size: 0.8em;
          }

          &:last-child {
            padding-left: 0.3em;
          }
        }
      }
    }

    > .user-revenue-details {
      flex: 0 1 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      > .date-select {
        flex: 0 0 auto;
        display: flex;
        align-items: center;

        > div {
          flex: 0 0 auto;

          &.right {
            flex: 0 1 100%;
          }
        }
      }

      > div {
        flex: 0 1 100%;

        .income-journal {
          height: 100%;
        }

        .ant-collapse {
          .ant-collapse-header > p {
            display: flex;
          }

          .ant-collapse-content-box > .ant-row > .ant-col {
            display: flex;
          }
        }
      }
    }
  }
}
</style>
